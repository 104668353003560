
<template>
  <div class="login-page">
    <div class="login-heard">
      <div class="logo-model">
        <img src="" alt="" />
      </div>
    </div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">湛渔</h3>
      </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <img :src="renPg" />
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="账户"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="off"
        />
      </el-form-item>

      <el-tooltip
        v-model="capsTooltip"
        content="Caps lock is On"
        placement="right"
        manual
      >
        <el-form-item prop="password">
          <span class="svg-container">
            <img :src="suoPg" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="密码"
            name="password"
            tabindex="2"
            autocomplete="off"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <el-icon
              :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </el-form-item>
      </el-tooltip>
      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        @click.native.prevent="handleLogin"
        >登录</el-button
      >
    </el-form>

    <el-dialog title="Or connect with" :visible.sync="showDialog">
      Can not be simulated on local, so please combine you own business
      simulation! ! !
      <br />
      <br />
      <br />
      <social-sign />
    </el-dialog>
  </div>
</template>
<script>
import renPg from '../assets/icon/ren.png'
import suoPg from '../assets/icon/suo.png'
import Vue from 'vue'
import { Input, Button, Form, Tooltip, Icon } from 'element-ui'
Vue.use(Input).use(Button).use(Form).use(Tooltip).use(Icon)
export default {
  name: 'login',
  data() {
    return {
      renPg,
      suoPg,
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '账户不能为空！' },
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空！' },
        ],
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
    }
  },
  computed: {},
  methods: {
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$axios({
            method: 'post',
            url: '/zhanjiang-admin/systemUserInfo/login',
            data: { userName: this.loginForm.username, password: this.loginForm.password },
          }).then((res) => {
            if (res.code === '00000') {
              console.log('data', res.data)
              let sessionId = res.data.accessToken
              let userinfo = res.data.userinfo

              localStorage.setItem('sessionId', sessionId)

              let url = this.$route.query.url
              this.$router.replace(url ? `/${this.$route.query.url}` : '/')
              this.loading = false
            } else {

              this.loading = false
            }
          }).catch(() => {

              this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
  },
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },
  created() {
    // this.$axios({
    //   method: 'post',
    //   url: '/zhanjiang-admin/systemUserInfo/login',
    //   data: { userName: 'admin', password: '123456' },
    // })
    //   .then((res) => {
    //     if (res.code === '00000') {
    //       console.log('data', res.data)
    //       let sessionId = res.data.accessToken
    //       let userinfo = res.data.userinfo
    //       localStorage.setItem('sessionId', sessionId)
    //       let url = this.$route.query.url
    //       this.$router.replace(url ? `/${this.$route.query.url}` : '/')
    //     }
    //   })
    //   .catch(() => {})
  },
  // 数据监听
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true,
    },
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>

<style lang="less" scoped>
@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #eeeeee;

/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

@bg: #283443;
@light_gray: #ffffff;
@cursor: #ffffff;

@supports (-webkit-mask: none) and (not (cater-color: @cursor)) {
  .login-page .el-input input {
    color: @cursor;
  }
}

/* reset element-ui css */
.login-page {
  /deep/ .el-input {
    display: inline-block;
    width: 85%;
    height: 53px;
    background: transparent;
    input {
      height: 47px;
      padding: 12px 5px 12px 5px;
      border: 0;
      border-radius: 0;
      background: transparent;
      color: white;

      -webkit-appearance: none;
      caret-color: @cursor;

      &:-webkit-autofill {
        height: 100%;
        color: white !important;
      }
      &:-internal-autofill-selected {
        background-color: transparent !important;

        -webkit-tap-highlight-color: rgba(0, 0, 8, 0);
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px #1d1e2c inset !important;

      -webkit-text-fill-color: white;
    }
  }

  /deep/ .el-form-item {
    position: relative;
    width: 338px;
    height: 56px;
    margin: 0 auto;
    margin-bottom: 43px !important;
    border: 1px solid #ffffff;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    color: #454545;
    &::after {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      background-image: linear-gradient(to right, #a786a6, #089ddd, #6be0bd);
      content: '';
    }
    .el-form-item__content {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .el-input {
        font-size: 12.5px !important;
      }
    }
    .el-form-item__error {
      margin-top: 8px;
    }
  }
}

.login-page {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  background: url('../assets/icon/loginBg.png');

  /deep/ .login-form {
    position: relative;
    top: 158px;
    overflow: hidden;
    width: 449px;
    max-width: 100%;
    height: 495px;
    margin-left: 58%;
    padding: 95px 35px 0;
    background: url('../assets/icon/biaodan.png') center center no-repeat;
    .el-button {
      display: block;
      width: 338px !important;
      height: 56px;
      margin: 0 auto !important;
      background-image: linear-gradient(to right, #433cf6, #8565ef, #42bdc6);
      color: #2aedff;
      font-size: 24px;
    }
  }

  .tips {
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 14px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    display: inline-block;
    width: 30px;
    margin-top: 10px;
    padding: 6px 5px 6px 15px;
    color: @dark_gray;
    vertical-align: middle;
  }

  .title-container {
    position: relative;
    padding-top: 53px;
    .title {
      margin: 0 auto 40px auto;
      color: #32eeff;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
    }
  }

  .show-pwd {
    position: absolute;
    top: 10px;
    right: 10px;
    color: @dark_gray;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
